@media (min-width: 1024px) {
 .w-offcanvas{
  width:50% !important;
 }
}
@media (min-width: 1024px) {
 .section-heading{
    display: flex;
    justify-items: start;
 }
}

@media (min-width: 1024px) {
   .editprofileofsalesrep{
      width: 50% !important;
   }   
}
@media (min-width: 320px) {
   .editaddress{
      height: 10% !important;
   }   
}